
import { createNamespacedHelpers } from 'vuex'
import product from '~/mixins/product'
import productPriceFrom from '~/mixins/productPriceFrom'
import userTypes from '~/store/user/types'
import GTM from '~/helpers/gtm'

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  name: 'MediTileProduct',
  mixins: [
    product,
    productPriceFrom,
  ],

  data () {
    return {
      isHover: false,
    }
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
      salesManagoId: userTypes.GET_SALESMANAGO_ID,
    }),

    getImagePlaceholder () {
      return this.product._typename === 'AppointmentWithDoctorProduct' || this.product._typename === 'AppointmentProduct'
        ? 'doctor_placeholder1.svg'
        : 'product_placeholder.svg'
    },

    getLabels () {
      const { promo, popular, newFlag, label } = this.product

      return [
        {
          name: 'label.new',
          value: newFlag,
        },
        {
          name: 'label.popular',
          value: popular,
        },
        {
          name: 'label.promotion',
          value: promo,
        },
        {
          name: label,
          value: !!label,
        },
      ]
    },

    // tylko dla vital jest inny text (i18n)
    detailsText () {
      return this.$config.storeCode === 'medicoverapteka' ? 'Explore the product' : 'Find out more'
    },

    price () {
      return this.isMediclubMember ? (this.minMediclubPrice || this.minRegularPrice) : this.minRegularPrice
    },
  },

  methods: {
    onProductTileClick () {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push(GTM.trackProductSelectFromList('select_item', this.product, this.price, this.salesManagoId))
    },
  },
}
